import {
  ChevronLeft,
  Download,
  Funnel,
  Grid,
  ListUl,
  Search,
  Upload,
} from "react-bootstrap-icons";
import { Link, NavLink } from "react-router-dom";
import BreadCrumList from "../Shared/BreadCrumb/BreadCrumList";

interface pageHeaderProps {
  listUrl?: string;
  gridUrl?: string;
  addUrl?: string;
  name?: string;
  viewType?:boolean
}

const PageHeader = (props: pageHeaderProps) => {
  const { listUrl = "", gridUrl = "", addUrl = "", name,viewType=false } = props;
  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <BreadCrumList />
        </div>
        <div className="col-auto">
          <div className="d-flex align-item-center pageHeaderItems">
            {!viewType && (
              <>
                <div className="me-3 align-self-center toggle-grid border border-secondary-subtle rounded-pill">
                  <div className="btn-group grid-toggle">
                    <NavLink
                      to={listUrl}
                      className={({ isActive }) => {
                        let classNames = "";
                        if (isActive) {
                          classNames = "d-block btn  btn-sm rounded-pill border-0 px-3 active";
                        } else {
                          classNames = "d-block btn  btn-sm rounded-pill border-0 px-3";
                        }
                        return classNames; // returns "registerButton" or "registerButton active"
                      }}
                    >
                      
                        <ListUl size={20} />
                      
                    </NavLink>
                    <NavLink
                      to={gridUrl}
                      className={({ isActive }) => {
                        let classNames = "";
                        if (isActive) {
                          classNames = "d-block btn  btn-sm rounded-pill border-0 px-3 active";
                        } else {
                          classNames = "d-block btn  btn-sm rounded-pill border-0 px-3";
                        }
                        return classNames; // returns "registerButton" or "registerButton active"
                      }}
                    >
                      
                        <Grid size={20} />
                      
                    </NavLink>
                  </div>
                </div>
                <div className="me-3">
                  <div className="input-group rounded-pill col-auto">
                    <input
                      type="text"
                      className="form-control rounded-start-pill border border-end-0 pe-0"
                      placeholder="Search Here"
                    />
                    <span className="input-group-text bg-white border border-start-0 rounded-end-pill cursor-pointer">
                      <Search />
                    </span>
                  </div>
                </div>
                <div className="cursor-pointer align-self-center">
                  <Funnel size={22} />
                </div>

                <div className="mx-3 align-self-center opacity-50">|</div>

                <div className="cursor-pointer align-self-center">
                  <Upload size={22} />
                </div>

                <div className="mx-3 align-self-center opacity-50">|</div>

                <div className="cursor-pointer align-self-center">
                  <Download size={22} />
                </div>
              </>
            )}

            
            {viewType === false ? (
              <Link to={addUrl}>
                <button className="sph-btn ms-3 " >
                  + {name}{" "}
                </button>
              </Link>
            ) : (
              <Link to={listUrl} className="mb-3">
                <span className="fw-medium text-secondary-emphasis " > 
              <span className="rounded-circle d-inline-block back-arrow">
                {" "}
               <ChevronLeft size={12}/>
              </span>{" "}
              Back
            </span>
              </Link>
            )} 
          </div>
        </div>
      </div>
    </>
  );
};

export default PageHeader;
