import { useEffect, useState } from "react";
import { ChevronDown, ChevronRight } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { MenuItem, menuList } from "../Data/menu";
import { useStore } from "../Shared/Hooks/Store";
export default function SubMenu() {
  const navigate = useNavigate();
  const { setStoreAccessorMenu } = useStore((state) => state);
  const [menuData, setMenuData] = useState<any[]>([]);
  const [secondLevelMenu, setSecondLevelMenu] = useState<MenuItem>();
  const getMenuItem = (menuList: any, level: number) => {
    return menuList.map((item: any) => {
      const menuItem = { ...item, level };
      if (item.child.length > 0) {
        menuItem.child = getMenuItem(item.child, level + 1);
      }
      return menuItem;
    });
  };

  useEffect(() => {
    const transformedMenu = getMenuItem(menuList, 0);
    setMenuData(transformedMenu);
  }, []);

  const handelBreadcrumbData = (data: MenuItem) => {
    setStoreAccessorMenu(data);
  };
  const handelURL = (data: string) => {
    navigate(`/${data}`);
  };
  const getClickedMenu = (
    menuList: any,
    name: string
  ): MenuItem | undefined => {
    for (const item of menuList) {
      if (item.name === name) {
        if (item.child && item.child.length > 0) {
          if (secondLevelMenu && secondLevelMenu.name === item.name) {
            setSecondLevelMenu(undefined);
          } else {
            setSecondLevelMenu(item);
          }
        } else {
          setSecondLevelMenu(undefined);
        }
        return item;
      }
      if (item.child.length > 0) {
        const Children = getClickedMenu(item.child, name);
        if (Children) {
          break;
        }
      }
    }
    return undefined;
  };
  const renderMenuItems = (items: any[]) => {
    return items.map((item: any) => (
      <ul key={item.name} className="nav flex-column">
        {item.level === 0 && <h6>{item.name}</h6>}

        {item.level === 1 && (
          <li
            onClick={() => {
              handelBreadcrumbData(item);
              getClickedMenu(menuList, item.name);
              handelURL(item.url);
            }}
            className="cursor-pointer"
          >
            {item.child && item.child.length > 0 ? (
              <span className="me-1">
                {secondLevelMenu && secondLevelMenu.name === item.name ? (
                  <ChevronDown />
                ) : (
                  <ChevronRight />
                )}
              </span>
            ) : (
              <i className="bull-dot me-2"></i>
            )}
            {item.name}
          </li>
        )}
        {secondLevelMenu && secondLevelMenu.name === item.name && (
          <section className="py-2 mt-1 sectionForGrey">
            <ul className="nav flex-column">
              {secondLevelMenu.child.map((trMenu) => (
                <li
                  onClick={() => {
                    handelBreadcrumbData(trMenu);
                    handelURL(trMenu.url);
                  }}
                >
                  {trMenu.name}
                </li>
              ))}
            </ul>
          </section>
        )}

        {item.child && renderMenuItems(item.child)}
      </ul>
    ));
  };
  return (
    <div>
      <div className="menu-column">{renderMenuItems(menuData)}</div>
    </div>
  );
}
