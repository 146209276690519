import React from "react";
import logo from "../assets/images/sphere-wms-logo.png";
import { ChevronDown } from "react-bootstrap-icons";
export default function Header() {
  return (
    <div>
      <nav className="navbar navbar-expand-sm navbar-dark bg-dark fixed-top header">
        <div className="container-fluid w-100">
          <a className="navbar-brand  mx-4" href="">
            <img src={logo} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mynavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-between"
            id="mynavbar"
          >
            <div className="d-flex">
              <div className="logo-separator me-4"></div>
              <div className="text-white align-self-center">Demo Company</div>
            </div>
            <div>
              <form className="d-flex">
                <div className="btn-group me-2">
                  <button
                    className="btn  bg-white text-black  dropdown-toggle rounded-pill"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    All Warehouses
                  </button>
                  <ul className="dropdown-menu">...</ul>
                </div>
                <div className="btn-group me-2">
                  <button
                    className="btn btn-secondary border-2 bg-transparent  dropdown-toggle rounded-pill"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    All Clients
                  </button>
                  <ul className="dropdown-menu">...</ul>
                </div>

                <div className="d-flex cursor-pointer">
                  <div className="rounded-circle bg-white text-black fw-bold p-2">
                    DC
                  </div>
                  <div className="align-self-center ms-1">
                    <ChevronDown color="white" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
