import React, { useState } from "react";
import { useStore } from "../Hooks/Store";
import { ChevronRight } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

export default function BreadCrumList() {
  const navigate = useNavigate();
  const { breadCrumList } = useStore((state) => state);
  const [breadCrumBox, setBreadCrumBox] = useState(false);
  // Check if the breadCrumBox should be set to true
  if (breadCrumBox === false && breadCrumList.length > 1) {
    setBreadCrumBox(true);
  }
  const handleBreadcrumbClick = (url: string) => {
    navigate(url);
  };
  return (
    <>
      {breadCrumBox && (
        <div className="sph-breadcrumb border border-info px-2 rounded text-secondary">
          {breadCrumList.map((crum, index) => (
            <small
              className="cursor-pointer"
              key={index}
              onClick={() => handleBreadcrumbClick(`/${crum.url}`)}
            >
              {index > 0 && (
                <span className="mx-1">
                  <ChevronRight size={12} />
                </span>
              )}
              {crum.name}
            </small>
          ))}
        </div>
      )}
    </>
  );
}
