import React, { useState, useRef } from "react";
import { ProgressBarProps } from "./TabProgressBar.type";
import {
  BorderBottom,
  CheckCircleFill,
  Circle,
  CircleFill,
} from "react-bootstrap-icons";

// Function to simulate click on Next button
export const clickCreateClientNextButton = () => {
  const nextButton = document.getElementById("createClientNextButton");
  if (nextButton) {
    nextButton.click();
  }
};

export const ProgressBar: React.FC<ProgressBarProps<any>> = ({ tabs }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [completedTabs, setCompletedTabs] = useState(
    Array(tabs.length).fill(false)
  );

  const handleNext = () => {
    setCurrentTab((prevTab) => Math.min(prevTab + 1, tabs.length - 1));
    const updatedCompletedTabs = [...completedTabs];
    updatedCompletedTabs[currentTab] = true;
    setCompletedTabs(updatedCompletedTabs);
  };
  const handlePrevious = () => {
    setCurrentTab((prevTab) => Math.max(prevTab - 1, 0));
  };

  return (
    <div>
      <div className="px-4 bg-white rounded-top">
        <ul className="nav">
          {tabs.map((item, index) => (
            <li
              className={`tab-item`}
              style={{
                borderBottom: index <= currentTab ? "3px solid #29afe8" : "",
              }}
            >
              <div className="ms-2 d-flex align-items-center">
                {index < currentTab ? (
                  <span className="nav-link completed">
                    <span>
                      <CheckCircleFill />
                    </span>{" "}
                    {item.label}
                  </span>
                ) : index == currentTab ? (
                  <span className="nav-link active">
                    <span>
                      <Circle />
                    </span>{" "}
                    {item.label}
                  </span>
                ) : (
                  <span className="nav-link">
                    <span>
                      <CircleFill />
                    </span>{" "}
                    {item.label}
                  </span>
                )}
              </div>
            </li>
          ))}
        </ul>

        <button
          onClick={handlePrevious}
          disabled={currentTab === 0}
          style={{ display: "none" }}
        >
          Previous
        </button>
        <button
          id="createClientNextButton" // ID assigned to the Next button
          onClick={() => {
            handleNext();
          }}
          disabled={currentTab === tabs.length - 1}
          style={{ display: "none" }}
        >
          Next
        </button>
      </div>
      <div>{tabs[currentTab].content}</div>
    </div>
  );
};

export default ProgressBar;
