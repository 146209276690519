import React from "react";
import { Pencil, PlusCircleFill } from "react-bootstrap-icons";
import "../../assets/css/style.css";
import { CardProps } from "./Card.type";

const Card: React.FC<CardProps> = ({ fields, data, header, getDetails }) => {
  const handleGetDetails = (item: any) => {
    if (getDetails) getDetails(item);
  };
  return (
    <>
      {/* Map through the data and render each item */}
      {data.map((item, index) => (
        <div
          key={index}
          className="col-lg-2 col-md-3 col-sm-6 col-xs-auto me-3 my-2 d-flex flex-column shadow grid-view-customer"
        >
          <div className="d-flex justify-content-between p-2">
            {header?.map((header, index) => (
              <div key={index} className="mt-2">
                <span className="fs-6">
                  <strong>
                    {header.selector ? header.selector(item) : "N/A"}
                  </strong>
                </span>
              </div>
            ))}
            <div
              className="cursor-pointer"
              onClick={() => handleGetDetails(item)}
            >
              <Pencil size="16" />
            </div>
          </div>
          <div className="border-top px-2 mb-2">
            {/* Render fields dynamically based on the fields prop */}
            {fields.map((field, index) => (
              <div key={index} className="mt-2">
                {field.name ? (
                  <span>
                    <strong>{field.name}</strong>:{" "}
                  </span>
                ) : (
                  ""
                )}
                <span className="text-secondary">
                  {field.selector ? field.selector(item) : "N/A"}
                </span>
              </div>
            ))}
          </div>
          <div className="border-top p-2 text-end cursor-pointer mt-auto">
            Active{" "}
            <button className="active-grid-button btn btn-info rounded-pill py-0 ps-3 pe-0">
              <PlusCircleFill color="#fff" size={12} />
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default Card;
