import React from "react";

export default function Toggle() {
  return (
    <div className="form-check form-switch sidebar-toggler mt-4 border-top pt-3">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        id="flexSwitchCheckChecked"
        checked
      />
      <label className="form-check-label">Toggle Sidebar</label>
    </div>
  );
}
