import { z } from "zod";
import {
  NAME_REGEX,
  NUMBER_REGEX,
  PHONE_NUM_CODE,
  POST_CODE,
} from "../../../Shared/Helper/Constance";
import {
  PATTERN_ERR_MSG,
  REQ_ERR_MSG,
} from "../../../Shared/Helper/ValidationMessage";

export class ShipToCode {
  public id: number;
  public shipCode: string;
  public firstName: string;
  public lastName: string;
  public address1: string;
  public address2: string;
  public phoneNo: string;
  public city: string;
  public state: string;
  public postalCode: string;
  public country: string;

  constructor() {
    this.id = 0;
    this.shipCode = "";
    this.firstName = "";
    this.lastName = "";
    this.address1 = "";
    this.address2 = "";
    this.phoneNo = "";
    this.city = "";
    this.state = "";
    this.postalCode = "";
    this.country = "";
  }
}

export const ShipToCodeSchema = z.object({
  id: z.number().optional(),
  shipCode: z
    .string()
    .min(1, { message: REQ_ERR_MSG })
    .refine((data) => NUMBER_REGEX.test(String(data)), {
      message: PATTERN_ERR_MSG,
    }),
  firstName: z.string().refine((data) => NAME_REGEX.test(data), {
    message: PATTERN_ERR_MSG,
  }),
  lastName: z.string().refine((data) => NAME_REGEX.test(data), {
    message: PATTERN_ERR_MSG,
  }),
  address1: z.string().refine((data) => NAME_REGEX.test(data), {
    message: PATTERN_ERR_MSG,
  }),
  address2: z.string().refine((data) => NAME_REGEX.test(data), {
    message: PATTERN_ERR_MSG,
  }),
  phoneNo: z.string().refine((data) => PHONE_NUM_CODE.test(data), {
    message: PATTERN_ERR_MSG,
  }),
  city: z.string().refine((data) => NAME_REGEX.test(data), {
    message: PATTERN_ERR_MSG,
  }),
  state: z.string().refine((data) => NAME_REGEX.test(data), {
    message: PATTERN_ERR_MSG,
  }),
  postalCode: z.string().refine((data) => POST_CODE.test(data), {
    message: PATTERN_ERR_MSG,
  }),
  country: z.string().refine((data) => data.trim() !== "", {
    message: "Country is required.",
  }),
});

export type ShipToCodeData = z.infer<typeof ShipToCodeSchema>;


export const dummyShipToCodeList: ShipToCodeData[] = [
];
