import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { ChevronUp, TrashFill } from "react-bootstrap-icons";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FormButton from "../../Components/Buttons/FormButton";
import InputFiled from "../../Components/FormTag/InputFiled";
import SelectFormTag from "../../Components/FormTag/SelectFormTag";
import PageHeader from "../../Layout/PageHeader";
import { placeholder } from "../../Shared/Helper/ValidationMessage";
import {
  CustomerBillTo,
  CustomerBillToData,
  CustomerBillToSchema,
} from "./CustomerBillTo.type";
import {
  ShipToCodeData,
  dummyShipToCodeList,
} from "./ShipToCode/ShipToCode.type";
import ShipToCodeAddEdit from "./ShipToCode/ShipToCodeAddEdit";
import ShipToCodeView from "./ShipToCode/ShipToCodeView";
import { countryList } from "../../Shared/Helper/Constance";


export default function CustomerAddEdit() {
  const pageName = "Customer";
  const navigation = useNavigate();
  const [custBillTo, setCustBillTo] = useState(new CustomerBillTo());
  const [shipToCodeList, setShipToCodeList] = useState(dummyShipToCodeList);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<CustomerBillToData>({
    defaultValues: custBillTo,
    resolver: zodResolver(CustomerBillToSchema),
  });
  const saveData: SubmitHandler<CustomerBillToData> = (data) => {};
  const handelCancelBtn = () => {
    navigation("/custList");
  };
  const handelAddEditShiToCodeData = (data: ShipToCodeData) => {
    setShipToCodeList((bl) => [...bl, data]);
  };
  return (
    <>
      <PageHeader
        addUrl="/addCustomer"
        listUrl="/custList"
        gridUrl="/custGrid"
        name={pageName}
        viewType={true}
      />
      <div className="clearfix"></div>
      <h5 className="text-start">Customer Bill To</h5>

      <div className="my-2 bg-white rounded py-3">
        <div className="text-end pe-4">
          <span className="badge rounded-pill sph-text-bg-light-grey cursor-pointer mx-2">
            <ChevronUp />
          </span>
          <span className="badge rounded-pill sph-text-bg-light-grey cursor-pointer mx-2">
            <TrashFill />
          </span>
        </div>
        <div className="d-flex px-2 mb-3">
          <div className="col px-2">
            <label>Bill to Code *</label>
            <div className="mt-2">
              <InputFiled
                type="text"
                name="billCode"
                register={register}
                className="form-control"
                errors={errors?.billCode}
                placeholder="Enter Bill To Code"
              />
            </div>
          </div>
          <div className="col px-2">
            <label>First Name</label>
            <div className="mt-2">
              <InputFiled
                type="text"
                name="firstName"
                register={register}
                className="form-control"
                errors={errors?.firstName}
                placeholder={placeholder}
              />
            </div>
          </div>
          <div className="col px-2">
            <label>Last Name</label>
            <div className="mt-2">
              <InputFiled
                type="text"
                name="lastName"
                register={register}
                className="form-control"
                errors={errors?.lastName}
                placeholder={placeholder}
              />
            </div>
          </div>
          <div className="col d-flex px-2 align-self-end">
            <div className="ms-4">
              <label>Inactive</label>
            </div>
            <div className="form-check form-switch sidebar-toggler">
              <div className="mx-2">
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  role="switch"
                  id=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex px-2 mb-3">
          <div className="col px-2">
            <label>Address 1</label>
            <div className="mt-2">
              <InputFiled
                type="text"
                name="address1"
                register={register}
                className="form-control"
                errors={errors?.address1}
                placeholder={placeholder}
              />
            </div>
          </div>
          <div className="col px-2">
            <label>Address 2</label>
            <div className="mt-2">
              <InputFiled
                type="text"
                name="address 2"
                register={register}
                className="form-control"
                errors={errors?.address2}
                placeholder={placeholder}
              />
            </div>
          </div>
          <div className="col px-2">
            <label>Phone Number</label>
            <div className="mt-2">
              <InputFiled
                type="text"
                name="phoneNo"
                register={register}
                className="form-control"
                errors={errors?.phoneNo}
                placeholder={placeholder}
              />
            </div>
          </div>
          <div className="col px-2"></div>
        </div>
        <div className="d-flex px-2  mb-3">
          <div className="col-2 px-2">
            <label>City</label>
            <div className="mt-2">
              <InputFiled
                type="text"
                name="city"
                register={register}
                className="form-control"
                errors={errors?.city}
                placeholder={placeholder}
              />
            </div>
          </div>
          <div className="col-2 px-2">
            <label>State</label>
            <div className="mt-2">
              <InputFiled
                type="text"
                name="state"
                register={register}
                className="form-control"
                errors={errors?.state}
                placeholder={placeholder}
              />
            </div>
          </div>
          <div className="col-2 px-2">
            <label>Zip/Postal Code</label>
            <div className="mt-2">
              <InputFiled
                type="text"
                name="postalCode"
                register={register}
                className="form-control"
                errors={errors?.postalCode}
                placeholder={placeholder}
              />
            </div>
          </div>

          <div className="col-2 px-2">
            <label>Country</label>
            <div className="mt-2">
              <SelectFormTag
                name="country"
                register={register}
                className="form-select"
                errors={errors?.country}
                placeholder="Enter"
              >
                {countryList.map((cc, index) => (
                  <option key={index} value={cc.value}>
                    {cc.value}
                  </option>
                ))}
              </SelectFormTag>
            </div>
          </div>
          <div className="col px-2"></div>
        </div>

        <div className="mt-5 px-3">
          <div className="d-flex justify-content-between align-item-center mb-3">
            <div>
              <h6 className="fw-bold">Ship to Code</h6>
            </div>
            <ShipToCodeAddEdit
              custBillTo={custBillTo}
              handelAddEditShiToCodeData={handelAddEditShiToCodeData}
              shipToCodeList={shipToCodeList}
            />
          </div>
          {shipToCodeList.length > 0 && (
            <ShipToCodeView shipToCodeList={shipToCodeList} />
          )}
        </div>
      </div>

      <div className="my-2 bg-white rounded py-3">
        <div className="d-flex flex-row-reverse">
          <FormButton
            className="sph-btn me-4"
            handleClick={handleSubmit(saveData)}
          >
            Submit
          </FormButton>
          <FormButton
            className="sph-btn sph-shift me-4"
            handleClick={handelCancelBtn}
          >
            Cancel
          </FormButton>
        </div>
      </div>
    </>
  );
}
