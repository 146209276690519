import { useState } from "react";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { SubmitHandler, useForm } from "react-hook-form";
import FormButton from "../../Components/Buttons/FormButton";
import FormButtonDisable from "../../Components/Buttons/FormButtonDisable";
import MultipleCheckboxDropdownGroup from "../../Components/FormTag/MultipleCheckboxDropdownGroup";
import MultipleCheckboxDropdown from "../../Components/MultipleCheckboxDropdown";
import { clickCreateClientNextButton } from "../../Shared/Tab/TabProgressBar";
import { Mapping } from "./Client.type";
import {
  clientWarehouseList,
  inboundOutboundList,
} from "../../Shared/Helper/Constance";

interface WarehouseWorkflow {
  header: string;
  data: string;
}

interface WarehousePanel {
  [key: string]: WarehouseWorkflow[];
}

export default function ClientMapping() {
  const [clientMappingData, setClientMappingData] = useState<Mapping>(
    new Mapping()
  );
  const [accordionPanelsList, setAccordionPanelsList] = useState<
    WarehousePanel[]
  >([]);
  const [collapseStates, setCollapseStates] = useState<boolean[]>(
    accordionPanelsList.map(() => false)
  );
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
    watch,
  } = useForm<Mapping>({
    defaultValues: clientMappingData,
  });
  const watchClientWarehouse = watch("clientWarehouse");
  const saveMapping: SubmitHandler<Mapping> = (data) => {
    const updatedAccordionPanelsList: WarehousePanel[] = [];
    data.clientWarehouse.forEach((warehouse) => {
      const warehouseObj: WarehousePanel = { [warehouse.label]: [] };
      data.clientWorkflow.forEach((workflow) => {
        warehouseObj[warehouse.label].push({
          header: workflow.category,
          data: workflow.key,
        });
      });

      updatedAccordionPanelsList.push(warehouseObj);
    });
    setAccordionPanelsList(updatedAccordionPanelsList);
  };
  const handelCancelBtn = () => {};
  const toggleCollapse = (index: number) => {
    const updatedCollapseStates = [...collapseStates];
    updatedCollapseStates[index] = !updatedCollapseStates[index];
    setCollapseStates(updatedCollapseStates);
  };
  const handelNext = () => {
    clickCreateClientNextButton();
  };
  return (
    <>
      <div className="my-2 bg-white rounded py-4">
        <div className="d-flex ps-4">
          <div className="col-5 px-2">
            <label>Select Warehouse(s)</label>
            <div className="mt-2">
              <MultipleCheckboxDropdown
                control={control}
                name="clientWarehouse"
                className="form-select"
                optionsList={clientWarehouseList}
                errors={errors.clientWarehouse}
                required={true}
              />
            </div>
          </div>
          <div className="col-5 px-2">
            <label>Select Workflow</label>
            <div className="mt-2">
              <MultipleCheckboxDropdownGroup
                control={control}
                name="clientWorkflow"
                optionsList={inboundOutboundList}
                className="form-select"
                watchData={!watchClientWarehouse.length}
                errors={errors.clientWorkflow}
                required={true}
              />
            </div>
          </div>
          <div className="d-flex align-items-end mb-2">
            <FormButtonDisable
              className="sph-btn ms-3 align-items-end"
              handleClick={handleSubmit(saveMapping)}
            >
              Add
            </FormButtonDisable>
          </div>
        </div>
      </div>
      {/* Accordion panels */}
      {accordionPanelsList.map((warehouseObj, index) => (
        <div
          key={index}
          className="my-2 bg-white rounded py-3"
          id={`accordionPanel${index}`}
        >
          <div className="d-flex justify-content-between align-item-center px-3">
            <div className="align-self-center">
              {collapseStates[index] === false ? (
                <div className="text-secondary fw-light">
                  <small>Warehouse</small>
                </div>
              ) : (
                ""
              )}
              <h6 className="fw-bold mb-0">{Object.keys(warehouseObj)[0]}</h6>
            </div>
            <div className="align-self-center">
              <span
                className="badge rounded-pill sph-text-bg-light-grey cursor-pointer"
                onClick={() => toggleCollapse(index)}
              >
                {collapseStates[index] ? <ChevronUp /> : <ChevronDown />}
              </span>
            </div>
          </div>
          {/* Conditional rendering based on collapse state */}
          {collapseStates[index] && (
            <div
              className="mt-2 px-3 accordion-collapse collapse show"
              id={`accordionCollapse${index}`}
            >
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 mapping-grid">
                  <div>
                    {" "}
                    <strong>Inbound Workflow</strong>
                  </div>
                  {warehouseObj[Object.keys(warehouseObj)[0]].map(
                    (workflow, idx) => (
                      <>
                        <div>
                          {workflow.header === "Inbound" && workflow.data}
                        </div>
                      </>
                    )
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div>
                    {" "}
                    <strong>Outbound Workflow</strong>
                  </div>
                  {warehouseObj[Object.keys(warehouseObj)[0]].map(
                    (workflow, idx) => (
                      <>
                        <div>
                          {workflow.header === "Outbound" && workflow.data}
                        </div>
                      </>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
      <div className="my-2 bg-white rounded py-3">
        <div className="d-flex flex-row-reverse">
          {accordionPanelsList.length > 0 && (
            <FormButton className="sph-btn me-4" handleClick={handelNext}>
              Next
            </FormButton>
          )}
          <FormButton
            className="sph-btn sph-shift me-4"
            handleClick={handelCancelBtn}
          >
            Cancel
          </FormButton>
        </div>
      </div>
    </>
  );
}
