import Multiselect from "multiselect-react-dropdown";
import { Controller } from "react-hook-form";
import { REQ_ERR_MSG } from "../Shared/Helper/ValidationMessage";

interface multipleCheckboxProps {
  control?: any;
  optionsList?: any[];
  className?: string;
  name: string;
  errors?: any;
  required: boolean;
}
export default function MultipleCheckboxDropdown(props: multipleCheckboxProps) {
  const {
    control,
    optionsList,
    className,
    name,
    required = false,
    errors,
  } = props;
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{ required: { value: required, message: REQ_ERR_MSG } }}
        render={({ field: { value, onChange } }) => (
          <Multiselect
            className={className}
            options={optionsList}
            placeholder="Select"
            showCheckbox={true}
            hidePlaceholder={true}
            closeOnSelect={false}
            onSelect={onChange}
            onRemove={onChange}
            selectedValues={value}
            displayValue="label"
          />
        )}
      />
      {errors && (
        <div>
          <span className="badge bg-danger"> &#9888;{errors.message}</span>
        </div>
      )}
    </>
  );
}
