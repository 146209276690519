import React from "react";
import { Modal } from "react-bootstrap";
import { XCircleFill } from "react-bootstrap-icons";
interface formModalProps {
  handleClose: () => void;
  show: boolean;
  modalTitle: string;
  children: React.ReactNode;
  footer: React.ReactNode;
}
export default function FormModal(props: formModalProps) {
  const { show, handleClose, modalTitle, children, footer } = props;
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        backdrop="static"
        centered={true}
      >
        <Modal.Header className="fw-bold">
          {modalTitle}
          <button
            type="button"
            className="btn-close sph-close py-0"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <XCircleFill />
          </button>
        </Modal.Header>
        {children}
        <Modal.Footer className="border-0">{footer}</Modal.Footer>
      </Modal>
    </>
  );
}
