import React from "react";
import Header from "./Header";
import SubMenu from "./SubMenu";
import ModuleMenu from "./ModuleMenu";
import Toggle from "./Toggle";

interface PageLayoutProps {
  children: React.ReactNode;
}
export default function PageLayout(props: PageLayoutProps) {
  const { children } = props;
  return (
    <div className="test">
      <Header />
      <div className="container-fluid pt-5 mt-5">
        <div className="row">
          <div className="col-md-3 col-lg-2 p-0">
            <div className="d-flex">
              <ModuleMenu />
              <div className="overwrap-menu col">
                <SubMenu />
                <Toggle />
              </div>
            </div>
          </div>
          {children}
          <div className="clearfix"></div>
        </div>
      </div>
    </div>
  );
}
