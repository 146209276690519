import Multiselect from "multiselect-react-dropdown";
import { Controller } from "react-hook-form";
import { REQ_ERR_MSG } from "../../Shared/Helper/ValidationMessage";
interface ListItem {
  category: string;
  key: string;
}
interface multipleCheckboxDropdownGroupProps {
  control?: any;
  optionsList?: any[];
  className?: string;
  name: string;
  watchData?: any;
  errors?: any;
  required: boolean;
}
export default function MultipleCheckboxDropdownGroup(
  props: multipleCheckboxDropdownGroupProps
) {
  const {
    control,
    name,
    optionsList,
    className,
    watchData,
    required = false,
    errors,
  } = props;
  return (
    <div>
      <Controller
        control={control}
        name={name}
        rules={{ required: { value: required, message: REQ_ERR_MSG } }}
        render={({ field: { value, onChange } }) => (
          <Multiselect
            className={className}
            displayValue="key"
            groupBy="category"
            options={optionsList}
            selectedValues={value} // Pass the selected values to the Multiselect
            onSelect={(selectedList: ListItem[], selectedItem: ListItem) => {
              onChange(selectedList);
            }}
            onRemove={(selectedList: ListItem[], removedItem: ListItem) => {
              const updatedList = selectedList.filter(
                (item: ListItem) => item !== removedItem
              );
              onChange(updatedList);
            }}
            showCheckbox
            disable={watchData}
          />
        )}
      />
      {errors && (
        <div>
          <span className="badge bg-danger"> &#9888;{errors.message}</span>
        </div>
      )}
    </div>
  );
}
