import { useNavigate } from "react-router-dom";
import AddButton from "../../Components/Buttons/AddButton";
import { data } from "../../Data/testData";
import PageHeader from "../../Layout/PageHeader";
import Card from "../../Shared/Cards/Card";
import { fields, header } from "../../Shared/Cards/Card.type";

const columns: fields[] = [
  {
    name: "First Name",
    selector: (row: { firstName: string }) => row.firstName,
  },
  {
    name: "Last Name",
    selector: (row: { lastName: string }) => row.lastName,
  },
  {
    name: "City",
    selector: (row: { city: string }) => row.city,
  },
  {
    name: "State",
    selector: (row: { state: string }) => row.state,
  },
];
const headers: header[] = [
  {
    name: "Ship To Code",
    selector: (row: { billCode: string }) => row.billCode,
  },
];
export default function CustomerGridView() {
  const pageName = "Customer";
  const navigation = useNavigate();
  const functionalityForCardLikeButton = () => {
    navigation("/addCustomer");
  };
  return (
    <>
      <PageHeader
        addUrl="/addCustomer"
        listUrl="/custList"
        gridUrl="/custGrid"
        name={pageName}
      />
      <div className="clearfix"></div>
      <div className="grid-view">
        <h5 className="text-start">{pageName}</h5>
        <div className="d-flex align-content-start flex-wrap">
          <AddButton
            handleClick={functionalityForCardLikeButton}
            label={pageName}
          />
          <Card fields={columns} data={data} header={headers} />
        </div>
      </div>
    </>
  );
}
