import React from "react";

interface SelectFormTagProps {
  register: any;
  errors: any;
  name: string;
  className: string;
  placeholder?: string;
  children: React.ReactNode;
}
export default function SelectFormTag(props: SelectFormTagProps) {
  const { register, errors, name, className, placeholder, children } = props;
  return (
    <div>
      <select name={name} {...register(name)} className={className}>
        {placeholder && (
          <option value="" disabled hidden>
            {placeholder}
          </option>
        )}
        {children}
      </select>
      {errors && (
        <div>
          <span className="badge bg-danger">
            &#9888;
            {errors.message}
          </span>
        </div>
      )}
    </div>
  );
}
