import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { PlusCircle } from "react-bootstrap-icons";
import { SubmitHandler, useForm } from "react-hook-form";
import FormButton from "../../../Components/Buttons/FormButton";
import FormModal from "../../../Components/FormTag/FormModal";
import InputFiled from "../../../Components/FormTag/InputFiled";
import SelectFormTag from "../../../Components/FormTag/SelectFormTag";
import { placeholder } from "../../../Shared/Helper/ValidationMessage";
import { CustomerBillTo } from "../CustomerBillTo.type";
import {
  ShipToCode,
  ShipToCodeData,
  ShipToCodeSchema,
} from "./ShipToCode.type";
import { countryList } from "../../../Shared/Helper/Constance";

interface ShipToProps {
  custBillTo?: CustomerBillTo;
  handelAddEditShiToCodeData: (data: ShipToCodeData) => void;
  shipToCodeList: ShipToCodeData[];
}
export default function ShipToCodeAddEdit(props: ShipToProps) {
  const { custBillTo, handelAddEditShiToCodeData, shipToCodeList } = props;
  const [shipToCode, setShipToCode] = useState(new ShipToCode());
  const [errMsg, setErrMsg] = useState("");
  const [isShowErr, setShowErr] = useState(false);
  const [hideErrMsgTimeout, setHideErrMsgTimeout] =
    useState<NodeJS.Timeout | null>(null);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<ShipToCodeData>({
    defaultValues: shipToCode,
    resolver: zodResolver(ShipToCodeSchema),
  });
  const [modalShow, setModalShow] = useState(false);
  const resetShipToData = () => {
    reset(shipToCode);
  };
  const handelModalButton = () => {
    if (!custBillTo || !custBillTo.billCode) {
      setShowErr(true);
      setErrMsg("Please fill the bill data");
      const timeout = setTimeout(() => {
        setShowErr(false);
        setErrMsg("");
      }, 2000);

      // Store the timeout ID in state
      setHideErrMsgTimeout(timeout);
    } else {
      setShowErr(false);
      setModalShow(!modalShow);
      resetShipToData();
    }
  };

  const saveData: SubmitHandler<ShipToCodeData> = (data) => {
    handelAddEditShiToCodeData(data);
    handelModalButton();
  };
  const footer = (
    <>
      <div className="my-2 bg-white rounded">
        <div className="d-flex flex-row-reverse">
          <FormButton
            className="sph-btn me-4"
            handleClick={handleSubmit(saveData)}
          >
            Add
          </FormButton>
          <FormButton
            className="sph-btn sph-shift me-4"
            handleClick={handelModalButton}
          >
            Cancel
          </FormButton>
        </div>
      </div>
    </>
  );
  useEffect(() => {
    reset(shipToCode);
  }, [shipToCode, reset]);
  return (
    <div>
      <div className="cursor-pointer" onClick={handelModalButton}>
        {isShowErr && (
          <div
            className="alert alert-danger text-center position-fixed alert-on-top"
            role="alert"
          >
            {errMsg}
          </div>
        )}
        <small>Add Ship to Code</small>
        <span className="cursor-pointer ms-2">
          <PlusCircle />
        </span>
      </div>
      <FormModal
        show={modalShow}
        handleClose={handelModalButton}
        modalTitle="Add Ship To Code"
        footer={footer}
      >
        <div className="my-2 bg-white rounded py-3">
          <div className="d-flex px-3 mb-3">
            <div className="col px-2">
              <label>Ship To Code</label>
              <div className="mt-2">
                <InputFiled
                  type="text"
                  register={register}
                  name="shipCode"
                  errors={errors.shipCode}
                  className="form-control"
                  placeholder="Enter Code"
                />
              </div>
            </div>
            <div className="col px-2">
              <label>First Name</label>
              <div className="mt-2">
                <InputFiled
                  type="text"
                  register={register}
                  name="firstName"
                  errors={errors.firstName}
                  className="form-control"
                  placeholder={placeholder}
                />
              </div>
            </div>
            <div className="col px-2">
              <label>Last Name</label>
              <div className="mt-2">
                <InputFiled
                  type="text"
                  register={register}
                  name="lastName"
                  errors={errors.lastName}
                  className="form-control"
                  placeholder={placeholder}
                />
              </div>
            </div>

            <div className="col  px-2">
              <label>Same as Bill to Code</label>
              <div className="form-check form-switch sidebar-toggler mt-3">
                <div>
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    role="switch"
                    id=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex px-3 mb-3">
            <div className="col px-2">
              <label>Address 1</label>
              <div className="mt-2">
                <InputFiled
                  type="text"
                  register={register}
                  name="address1"
                  errors={errors.address1}
                  className="form-control"
                  placeholder={placeholder}
                />
              </div>
            </div>
            <div className="col px-2">
              <label>Address 2</label>
              <div className="mt-2">
                <InputFiled
                  type="text"
                  register={register}
                  name="address2"
                  errors={errors.address2}
                  className="form-control"
                  placeholder={placeholder}
                />
              </div>
            </div>
            <div className="col px-2">
              <label>Phone Number</label>
              <div className="mt-2">
                <InputFiled
                  type="text"
                  register={register}
                  name="phoneNo"
                  errors={errors.phoneNo}
                  className="form-control"
                  placeholder={placeholder}
                />
              </div>
            </div>
            <div className="col px-2"></div>
          </div>

          <div className="d-flex px-3 mt-3 mb-3">
            <div className="col-2 px-2">
              <label>City</label>
              <div className="mt-2">
                <InputFiled
                  type="text"
                  register={register}
                  name="city"
                  errors={errors.city}
                  className="form-control"
                  placeholder={placeholder}
                />
              </div>
            </div>
            <div className="col-2 px-2">
              <label>State</label>
              <div className="mt-2">
                <InputFiled
                  type="text"
                  register={register}
                  name="state"
                  errors={errors.state}
                  className="form-control"
                  placeholder={placeholder}
                />
              </div>
            </div>
            <div className="col-2 px-2">
              <label>Zip/Postal Code</label>
              <div className="mt-2">
                <InputFiled
                  type="text"
                  register={register}
                  name="postalCode"
                  errors={errors?.postalCode}
                  className="form-control"
                  placeholder={placeholder}
                />
              </div>
            </div>

            <div className="col-2 px-2">
              <label>Country</label>
              <div className="mt-2">
                <SelectFormTag
                  name="country"
                  register={register}
                  className="form-select"
                  errors={errors?.country}
                  placeholder="Enter"
                >
                  {countryList.map((cc, index) => (
                    <option key={index} value={cc.value}>
                      {cc.value}
                    </option>
                  ))}
                </SelectFormTag>
              </div>
            </div>
            <div className="col px-2"></div>
          </div>
        </div>
      </FormModal>
    </div>
  );
}
