import { create } from "zustand";
import { MenuItem, menuList as initialMenuList } from "../../Data/menu";

interface SoteState {
  //Used for menu start
  menuList: MenuItem[];
  breadCrumList: MenuItem[];
  setStoreAccessorMenu: (breadCrum: MenuItem) => void;
  //Used for menu end
}

export const useStore = create<SoteState>((set) => ({
  menuList: initialMenuList,

  breadCrumList: [],
  setStoreAccessorMenu: (breadCrum) => {
    const newBreadCrumListItm: MenuItem[] = [];

    const findParent = (menu: MenuItem[], target: string): boolean => {
      for (const item of menu) {
        if (item.name === target) {
          newBreadCrumListItm.push(item);
          return true;
        } else if (item.child.length > 0 && findParent(item.child, target)) {
          newBreadCrumListItm.push(item);
          return true;
        }
      }
      return false;
    };

    for (const item of initialMenuList) {
      if (findParent([item], breadCrum.name)) {
        break;
      }
    }

    set({ breadCrumList: newBreadCrumListItm.reverse() });
  },
  //Used for menu end
}));
