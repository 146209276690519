import { useParams } from "react-router-dom";
import { ClientData } from "../../Data/testData";
import PageHeader from "../../Layout/PageHeader";
import Tab from "../../Shared/Tab/Tab";

export default function ClientDetails() {
  const pageName = "Client";
  const { id } = useParams<{ id: string }>();
  const client = id ? ClientData.find((item) => item.id === parseInt(id)) : "";
  const tabs = [
    {
      order: 0,
      label: "Basic Information",
      content: client ? renderBasicInfo(client) : "Loading...",
      visibility: true,
      status: true,
    },
    {
      order: 1,
      label: "Additional Changes",
      content: client ? renderAdditionalChanges(client) : "Loading...",
      visibility: true,
      status: true,
    },
    {
      order: 2,
      label: "Settings",
      content: client ? renderSettings(client) : "Loading...",
      visibility: true,
      status: true,
    },
  ];
  return (
    <>
      <PageHeader listUrl="/clientGrid" name={pageName} viewType={true} />
      <div className="clearfix"></div>
      <Tab tabs={tabs}  listLength={0}/>
      <div className="my-2 bg-white rounded py-3">
        <div className="d-flex flex-row-reverse">
          <button className="sph-btn me-4">Edit</button>
        </div>
      </div>
    </>
  );
  function renderBasicInfo(client: any) {
    return (
      <div>
        <div id="tab-1">
          <div className="my-2 bg-white rounded">
            <div className="p-4 d-flex flex-wrap">
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Client Code</small>
                </div>
                <div>{client.clientCode}</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Client Name</small>
                </div>
                <div>{client.clintName}</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Phone</small>
                </div>
                <div>000-0000-000000</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Email</small>
                </div>
                <div>info@company.com</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Address 1</small>
                </div>
                <div>W lane Ave</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Address 2</small>
                </div>
                <div>-</div>
              </div>

              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>City</small>
                </div>
                <div>{client.city}</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>State/Province</small>
                </div>
                <div>{client.state}</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Zip/Postal Code</small>
                </div>
                <div>123987</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Country</small>
                </div>
                <div>USA</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderAdditionalChanges(client: any) {
    return (
      <div>
        <div id="tab-2" className="">
          <div className="my-2 bg-white rounded">
            <div className="p-4 d-flex flex-wrap">
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Bill Code</small>
                </div>
                <div>123987</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Type</small>
                </div>
                <div>Autometic Billing</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Description</small>
                </div>
                <div>Storage</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Rate</small>
                </div>
                <div>$25.00</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Status</small>
                </div>
                <div>
                  {client.status === true ? (
                    <span className="badge text-bg-light border border-2 px-4 border-info-subtle cursor-pointer">
                      Active
                    </span>
                  ) : (
                    <span className="badge text-bg-light border border-2 px-4 border-info-subtle cursor-pointer">
                      Inactive
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderSettings(client: any) {
    return (
      <div>
        <div id="tab-3" className="">
          <div className="my-2 bg-white rounded">
            <div className="px-4 pt-2 col-12 border-bottom border-light-subtle">
              <h6>Inbound Setting</h6>
            </div>
            <div className="px-4 pt-2 col-12">
              <h6>ASN Field</h6>
            </div>
            <div className="px-5 py-2 d-flex flex-wrap">
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Reference 1</small>
                </div>
                <div>-</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Reference 2</small>
                </div>
                <div>-</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Check in</small>
                </div>
                <div>Yes</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Onloading</small>
                </div>
                <div>Yes</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Receiving</small>
                </div>
                <div>Yes</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Purchase Order</small>
                </div>
                <div>Yes</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Cross Dock</small>
                </div>
                <div>Yes</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Allow Backorder</small>
                </div>
                <div>Yes</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Receipt Email</small>
                </div>
                <div>Yes</div>
              </div>
            </div>
          </div>
          <div className="my-2 bg-white rounded">
            <div className="px-4 pt-2 col-12 border-bottom border-light-subtle">
              <h6>Outbound Field</h6>
            </div>
            <div className="px-5 py-4 d-flex flex-wrap">
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Reference 1</small>
                </div>
                <div>-</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Reference 2</small>
                </div>
                <div>-</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Reference 3</small>
                </div>
                <div>-</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Reference 4</small>
                </div>
                <div>-</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Allow Backorder</small>
                </div>
                <div>Yes</div>
              </div>
              <div className="mb-4 col-4">
                <div className="text-secondary fw-light">
                  <small>Shipment Emails</small>
                </div>
                <div>Yes</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
