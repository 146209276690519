import { PlusCircle, ThreeDotsVertical } from "react-bootstrap-icons";
import Table, { Column } from "../../Components/Table";
import { data } from "../../Data/testData";
import PageHeader from "../../Layout/PageHeader";
import "../../assets/css/style.css";

export default function CustomerOverview() {
  const columns: Column[] = [
    {
      name: "Ship To Code",
      selector: (row: { billCode: string }) => row.billCode,
    },
    {
      name: "First Name",
      selector: (row: { firstName: string }) => row.firstName,
    },
    {
      name: "Last Name",
      selector: (row: { lastName: string }) => row.lastName,
    },
    {
      name: "City",
      selector: (row: { city: string }) => row.city,
    },
    {
      name: "State",
      selector: (row: { state: string }) => row.state,
    },
    {
      name: "Status",
      selector: (row: { status: string }) => {
        return row.status === "Active" ? (
          <span className="badge text-bg-light border border-2 px-3 border-info-subtle cursor-pointer">
            {row.status}
          </span>
        ) : (
          <span className="badge text-bg-light border border-2 px-3 border-danger-subtle cursor-pointer">
            {row.status}
          </span>
        );
      },
    },
    {
      name: <PlusCircle />,
      selector: (row: any) => (
        <>
          <span
            className="cursor-pointer dropdown-toggle action-menu"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <ThreeDotsVertical />
          </span>
          <ul className="dropdown-menu action-items">
            <li
              className="cursor-pointer ms-3 mb-1"
              onClick={() => handelEditAction(row)}
            >
              Edit
            </li>
            <li
              className="cursor-pointer ms-3 mb-1"
              onClick={() => handelInactiveAction(row)}
            >
              Inactive
            </li>
            <li
              className="cursor-pointer ms-3 mb-1"
              onClick={handelDeleteAction}
            >
              Delete
            </li>
          </ul>
        </>
      ),
    },
  ];
  const handelEditAction = (data: any) => {};
  const handelInactiveAction = (data: any) => {};
  const handelDeleteAction = (data: any) => {};
  const pageName = "Customer";
  return (
    <>
      <PageHeader
        addUrl="/addCustomer"
        listUrl="/custList"
        gridUrl="/custGrid"
        name={pageName}
      />
      <div className="clearfix"></div>
      <h5 className="text-start">{pageName}</h5>
      <div className="customerTableMainDiv">
        <Table columns={columns} data={data} />
      </div>
    </>
  );
}
