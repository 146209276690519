import { useNavigate } from "react-router-dom";
import AddButton from "../../Components/Buttons/AddButton";
import { Column } from "../../Components/Table";
import { ClientData } from "../../Data/testData";
import PageHeader from "../../Layout/PageHeader";
import Card from "../../Shared/Cards/Card";
import { header } from "../../Shared/Cards/Card.type";
import clientImg from "../../assets/images/clinet-gv.jpg";
export default function ClientGridView() {
  const pageName = "Client";
  const columns: Column[] = [
    {
      name: "",
      selector: (row: { logo: string }) => <img src={clientImg} />,
    },
  ];
  const headers: header[] = [
    {
      name: "Client Name",
      selector: (row: { clintName: string; clientCode: string }) =>
        row.clintName + "(" + row.clientCode + ")",
    },
  ];
  const navigation = useNavigate();
  const functionalityForCardLikeButton = () => {
    navigation("/clientCreate");
  };
  const getClientDtls = (item: any) => {
    navigation(`/clientDetails/${item.id}`);
  };
  return (
    <>
      <PageHeader
        addUrl="/clientCreate"
        listUrl="/clientList"
        gridUrl="/clientGrid"
        name={pageName}
      />
      <div className="clearfix"></div>
      <div className="grid-view">
        <h5 className="text-start">Client</h5>
        <div className="d-flex align-content-start flex-wrap">
          <AddButton
            handleClick={functionalityForCardLikeButton}
            label={pageName}
          />
          <Card
            fields={columns}
            data={ClientData}
            header={headers}
            getDetails={getClientDtls}
          />
        </div>
      </div>
    </>
  );
}
