export class BasicInfo {
  public clientId: number;
  public clientCode: string;
  public clientName: string;
  public clientPhone: string;
  public clientEmail: string;
  public clientAddress1: string;
  public clientAddress2: string;
  public clientCity: string;
  public clientState: string;
  public clientZip: string;
  public clientCountry: string;
  public clientImg: string;
  public clientImgLogoStatus: boolean;
  public clientStatus: boolean;

  constructor() {
    this.clientId = 0;
    this.clientCode = "";
    this.clientName = "";
    this.clientPhone = "";
    this.clientEmail = "";
    this.clientAddress1 = "";
    this.clientAddress2 = "";
    this.clientCity = "";
    this.clientState = "";
    this.clientZip = "";
    this.clientCountry = "";
    this.clientImg = "";
    this.clientImgLogoStatus = false;
    this.clientStatus = false;
  }
}
export class Mapping {
  public clientWarehouse: { label: string; value: string }[];
  public clientWorkflow: { category: string; key: string }[];

  constructor() {
    this.clientWarehouse = [];
    this.clientWorkflow = [];
  }
}
export interface ClientData {
  basicInfo: BasicInfo;
  mapping: Mapping;
}
export const Client: ClientData = {
  basicInfo: new BasicInfo(),
  mapping: new Mapping(),
};
