import { PlusLg } from "react-bootstrap-icons";

interface BtnProps {
  handleClick: () => void;
  label: String;
}
export default function AddButton(props: BtnProps) {
  const { handleClick, label } = props;
  return (
    <div
      className="col-lg-2 col-md-3 col-sm-6 col-xs-auto me-3 my-2 px-xs-3 d-flex add-customer justify-content-center cursor-pointer"
      onClick={handleClick}
    >
      <div className="align-self-center">
        <PlusLg size="16" /> {label}
      </div>
    </div>
  );
}
