export const data = [
    { 
        "id":1,
        "billCode": "1293",
        "firstName": "Lenovo Laptop",
        "lastName": "Japan",
        "city": "Tokyo",
        "state": "Tokyo",
        "status": "Active"
    },
    {
        "id":2,
        "billCode": "2356",
        "firstName": "Samsung Galaxy S21",
        "lastName": "Seoul",
        "city": "Seoul",
        "state": "Seoul",
        "status": "Active"
    },
    {
        "id":3,
        "billCode": "3789",
        "firstName": "Apple MacBook Pro",
        "lastName": "USA",
        "city": "New York",
        "state": "New York",
        "status": "Inactive"
    },
    {
        "id":4,
        "billCode": "4871",
        "firstName": "Sony PlayStation 5",
        "lastName": "China",
        "city": "Beijing",
        "state": "Beijing",
        "status": "Active"
    },
    {
        "id":5,
        "billCode": "5982",
        "firstName": "Dell XPS 15",
        "lastName": "Ireland",
        "city": "Dublin",
        "state": "Dublin",
        "status": "Active"
    },
    {
        "id":6,
        "billCode": "6543",
        "firstName": "Huawei P40 Pro",
        "lastName": "China",
        "city": "Shanghai",
        "state": "Shanghai",
        "status": "Active"
    },
    {
        "id":7,
        "billCode": "7891",
        "firstName": "Acer Predator 300",
        "lastName": "Taiwan",
        "city": "Taipei",
        "state": "Taipei",
        "status": "Active"
    },
    {
        "id":8,
        "billCode": "8234",
        "firstName": "LG OLED TV",
        "lastName": "South Korea",
        "city": "Seoul",
        "state": "Seoul",
        "status": "Inactive"
    },
    {
        "id":9,
        "billCode": "9456",
        "firstName": "Microsoft Surface Pro",
        "lastName": "USA",
        "city": "New York",
        "state": "New York",
        "status": "Active"
    },
];


export const ClientData=[
    {
        "id":1,
        "clientCode":'2453',
        "clintName":'Cutis Pharma',
        "wareHouse":'West Houston',
        "city":'Ohio',
        "state":'Columbus',
        "status":true,
        "logo": "https://logowik.com/content/uploads/images/ceva-lojistics6229.logowik.com.webp"
    },
    {
        "id":2,
        "clientCode":'2454',
        "clintName":'Google',
        "wareHouse":'West Houston',
        "city":'Ohio',
        "state":'Columbus',
        "status":true,
        "logo":"https://logowik.com/content/uploads/images/ceva-lojistics6229.logowik.com.webp"
    },
    {
        "id":3,
        "clientCode":'2455',
        "clintName":'Google',
        "wareHouse":'West Houston',
        "city":'Ohio',
        "state":'Columbus',
        "status":true,
        "logo":"https://logowik.com/content/uploads/images/ceva-lojistics6229.logowik.com.webp"
    },
]