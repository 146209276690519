import { BrowserRouter } from "react-router-dom";
// import "./App.css";
import PageLayout from "./Layout/PageLayout";
import Pages from "./Layout/Pages";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <PageLayout>
          <Pages />
        </PageLayout>
      </BrowserRouter>
    </div>
  );
}

export default App;
