export type MenuItem = {
  name: string;
  url:string;
  child: MenuItem[] | [];
};

export const menuList: MenuItem[] = [
  {
    name: "Account",
    url:'account',
    child: [
      {
        name: "Workflow Settings",
        url:'wfSetting',
        child: [],
      },
      {
        name: "Warehouse",
        url:'Warehouse',
        child: [],
      },
      {
        name: "Billing Codes",
        url:'billingCodes',
        child: [],
      },

      {
        name: "Client",
        url:'clientList',
        child: [
          {
            name: "Overview",
            url:'overview',
            child: [],
          },
          {
            name: "Customers",
            url:'custList',
            child: [],
          },
          {
            name: "Suppliers",
            url:'suppliers',
            child: [],
          },
          {
            name: "Items",
            url:'items',
            child: [],
          },
          {
            name: "Inventory Status",
            url:'inventoryStatus',
            child: [],
          },
          {
            name: "Carrier",
            url:'carrier',
            child: [],
          },
        ],
      },
      {
        name: "Roles",
        url:'roles',
        child: [
          {
            name: "Users",
            url:'users',
            child: [],
          },
        ],
      },
      // {
      //   name: " Printer Settings",
      //   child: [],
      // },
    ],
  },
];
