import { PlusCircle, ThreeDotsVertical } from "react-bootstrap-icons";
import Table, { Column } from "../../Components/Table";
import { ClientData } from "../../Data/testData";
import PageHeader from "../../Layout/PageHeader";

export default function ClientOverview() {
  const pageName = "Client";
  const columns: Column[] = [
    {
      name: "Client Name",
      selector: (row: { clintName: string }) => row.clintName,
    },
    {
      name: "Ware House ",
      selector: (row: { wareHouse: string }) => row.wareHouse,
    },
    {
      name: "City",
      selector: (row: { city: string }) => row.city,
    },
    {
      name: "State",
      selector: (row: { state: string }) => row.state,
    },
    {
      name: "Status",
      selector: (row: { status: boolean }) => {
        return row.status === true ? (
          <span className="badge text-bg-light border border-2 px-3 border-info-subtle cursor-pointer">
            Active
          </span>
        ) : (
          <span className="badge text-bg-light border border-2 px-3 border-danger-subtle cursor-pointer">
            Inactive
          </span>
        );
      },
    },
    {
      name: <PlusCircle />,
      selector: (row: any) => (
        <>
          <span
            className="cursor-pointer dropdown-toggle action-menu"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <ThreeDotsVertical />
          </span>
          <ul className="dropdown-menu action-items">
            <li
              className="cursor-pointer ms-3 mb-1"
              onClick={() => handelEditAction(row)}
            >
              Edit
            </li>
            <li
              className="cursor-pointer ms-3 mb-1"
              onClick={() => handelInactiveAction(row)}
            >
              Inactive
            </li>
            <li
              className="cursor-pointer ms-3 mb-1"
              onClick={handelDeleteAction}
            >
              Delete
            </li>
          </ul>
        </>
      ),
    },
  ];
  const handelEditAction = (data: any) => {};
  const handelInactiveAction = (data: any) => {};
  const handelDeleteAction = (data: any) => {};

  return (
    <>
      <PageHeader
        addUrl="/clientCreate"
        listUrl="/clientList"
        gridUrl="/clientGrid"
        name={pageName}
      />
      <div className="clearfix"></div>

      <h5 className="text-start">{pageName}</h5>
      <div className="customerTableMainDiv">
        <Table columns={columns} data={ClientData} />
      </div>
    </>
  );
}
