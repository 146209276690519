import React from "react";
interface BtnProps {
  children: React.ReactNode;
  handleClick?: () => void;
  className?: string;
}
export default function FormButton(props: BtnProps) {
  const { children, handleClick, className } = props;

  return (
    <div>
      <button onClick={handleClick} className={className}>
        {children}
      </button>
    </div>
  );
}
