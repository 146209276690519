import React from 'react'

export default function ModuleMenu() {
  return (
    <div className='d-flex flex-column col-auto mx-3'>
                                <div className='menu-action mt-5'>
                                    <div className='dasboard-icon'></div>
                                </div>
                                <div className='menu-action '>
                                    <div className='inbound-icon'></div>
                                </div>
                                <div className='menu-action'>
                                    <div className='outbound-icon'></div>
                                </div>
                                <div className='menu-action'>
                                    <div className='inventory-icon'></div>
                                </div>
                                <div className='menu-action active'>
                                    <div className='settings-icon'></div>
                                </div>
                                <div className='menu-action'>
                                    <div className='inquiry-icon'></div>
                                </div>
                            </div>
  )
}
