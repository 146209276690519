import React from "react";
import {
  REQ_ERR_MSG,
} from "../../Shared/Helper/ValidationMessage";

interface InputFiledProps {
  type: string;
  register: any;
  errors: any;
  name: string;
  className?: string;
  placeholder?: string;
  required?: boolean;
  pattern?: {
    type: any;
    message: string;
  };

}
export default function InputFiled(props: InputFiledProps) {
  const {
    type,
    register,
    errors,
    name,
    className,
    placeholder,
    required=false,
    pattern,
    
  } = props;

  return (
    <>
      <input
        type={type}
        name={name}
        className={className}
        {...register(name, {
          ...(required && {
            required: {
              value: true,
              message: REQ_ERR_MSG,
            },
          }),
          ...(pattern && {
            pattern: {
              value: pattern.type,
              message: pattern.message,
            },
          }),
        })}
        placeholder={placeholder}
      />
      {errors && (
        <div>
          <span className="badge bg-danger"> &#9888;{errors.message}</span>
        </div>
      )}
    </>
  );
}
