import { useState } from "react";

// Define the Column type for the data grid
export type Column = {
  name: any;
  selector?: (row: any) => any;
  cell?: JSX.Element;
};

// Define the props for the DataGridComponent
type DataTableProps = {
  columns: Column[];
  data: any[];
  enableSorting?: boolean;
  enableGlobalSearching?: boolean;
};

const Table: React.FC<DataTableProps> = ({
  columns,
  data,
  enableSorting,
  enableGlobalSearching,
}) => {
  // State for managing selected rows
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState(new Set<number>());

  // State for sorting
  const [sortColumn, setSortColumn] = useState<Column | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  // State for search text
  const [searchText, setSearchText] = useState("");

  // Function to handle checkbox change for a row
  const handleRowCheckboxChange = (index: number) => {
    const newSelectedRows = new Set(selectedRows);
    if (selectedRows.has(index)) {
      newSelectedRows.delete(index);
    } else {
      newSelectedRows.add(index);
    }
    setSelectedRows(newSelectedRows);
  };

  // Function to handle select all checkbox
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows(new Set<number>());
    } else {
      const allRows = new Set<number>();
      for (let i = 0; i < data.length; i++) {
        allRows.add(i);
      }
      setSelectedRows(allRows);
    }
    setSelectAll(!selectAll);
  };

  // Function to handle column sorting
  const handleColumnSort = (column: Column) => {
    if (sortColumn && sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  // Function to filter rows based on search text
  const filterRows = (row: any, searchText: string) => {
    return Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchText.toLowerCase())
    );
  };

  return (
    <div>
      {enableGlobalSearching && (
        <input
          type="text"
          placeholder="Search..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      )}
      <table className="table sph-table">
        <thead>
          <tr>
            <th scope="col" className="text-center">
              <input
                className="form-check-input sph-checkbox-th"
                type="checkbox"
                value=""
                id=""
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </th>
            {columns.map((col, index) => (
              <th
                scope="col"
                key={index}
                onClick={() => enableSorting && handleColumnSort(col)}
              >
                {col.name}{" "}
                {enableSorting && sortColumn === col && (
                  <span>{sortDirection === "asc" ? "↑" : "↓"}</span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data
            .filter((row) => !searchText || filterRows(row, searchText))
            .sort((a, b) => {
              if (sortColumn) {
                const aValue = sortColumn.selector
                  ? sortColumn.selector(a)
                  : "";
                const bValue = sortColumn.selector
                  ? sortColumn.selector(b)
                  : "";
                if (sortDirection === "asc") {
                  return aValue > bValue ? 1 : -1;
                } else {
                  return aValue < bValue ? 1 : -1;
                }
              }
              return 0;
            })
            .map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td className="rounded-start text-center">
                  <input
                    className="form-check-input sph-checkbox"
                    value=""
                    id=""
                    type="checkbox"
                    checked={selectedRows.has(rowIndex)}
                    onChange={() => handleRowCheckboxChange(rowIndex)}
                  />
                </td>
                {columns.map((col, colIndex) => (
                  <td key={colIndex}>
                    {col.selector ? col.selector(row) : col.cell}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
export default Table;
