import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FormButton from "../../Components/Buttons/FormButton";
import InputFiled from "../../Components/FormTag/InputFiled";
import SelectFormTag from "../../Components/FormTag/SelectFormTag";
import {
  ALPHANUM_REGEX,
  ALPHANUM_SPACE_REGEX,
  ALPHA_ONE_SPACE_REGEX,
  EMAIL,
  PHONE_NUM_CODE,
  countryList,
  stateList,
  zipList,
} from "../../Shared/Helper/Constance";
import {
  PATTERN_ERR_MSG,
  placeholder,
} from "../../Shared/Helper/ValidationMessage";
import { BasicInfo, ClientData, Mapping } from "./Client.type";
import { clickCreateClientNextButton } from "../../Shared/Tab/TabProgressBar";

interface BasicInfoProps {
  handelAllTabData?: (data: ClientData) => void;
  clientData?: ClientData;
}
export default function ClientBasicInfo(props: BasicInfoProps) {
  const navigation = useNavigate();
  const { handelAllTabData, clientData } = props;
  const [clientBasicData, setClientBasicData] = useState<BasicInfo>(
    new BasicInfo()
  );
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<BasicInfo>({
    defaultValues: clientBasicData,
  });
  const nextData: SubmitHandler<BasicInfo> = (data) => {
    console.log(data);
    const newData: ClientData = {
      basicInfo: data,
      mapping: props.clientData?.mapping || new Mapping(),
    };
    handelAllTabData?.(newData);
    clickCreateClientNextButton();
  };
  const handelCancelBtn = () => {};
  return (
    <>
      <div className="my-2 bg-white rounded py-3">
        <form>
          <div className="d-flex px-2">
            <div className="col-4 px-2">
              <label>Client Code *</label>
              <div className="mt-2">
                <InputFiled
                  type="text"
                  register={register}
                  name="clientCode"
                  errors={errors.clientCode}
                  className="form-control"
                  placeholder="Enter Code"
                  required={true}
                  pattern={{ type: ALPHANUM_REGEX, message: PATTERN_ERR_MSG }}
                />
              </div>
            </div>
            <div className="col-4 px-2">
              <label>Client Name *</label>
              <div className="mt-2">
                <InputFiled
                  type="text"
                  register={register}
                  name="clientName"
                  errors={errors.clientName}
                  className="form-control"
                  placeholder="Enter Name"
                  required={true}
                  pattern={{
                    type: ALPHA_ONE_SPACE_REGEX,
                    message: PATTERN_ERR_MSG,
                  }}
                />
              </div>
            </div>
            <div className="col-4 d-flex px-2 justify-content-end">
              <div className="ms-4">
                <label>Inactive</label>
              </div>
              <div className="form-check form-switch sidebar-toggler">
                <div className="mx-2">
                  <InputFiled
                    type="checkbox"
                    register={register}
                    name="clientStatus"
                    errors={errors.clientStatus}
                    className="form-check-input cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex px-2 mt-3">
            <div className="col-4 px-2">
              <label>Phone</label>
              <div className="mt-2">
                <InputFiled
                  type="text"
                  register={register}
                  name="clientPhone"
                  errors={errors.clientPhone}
                  className="form-control"
                  placeholder="0000-0000-00000"
                  pattern={{ type: PHONE_NUM_CODE, message: PATTERN_ERR_MSG }}
                />
              </div>
            </div>
            <div className="col-4 px-2">
              <label>Email</label>
              <div className="mt-2">
                <InputFiled
                  type="text"
                  register={register}
                  name="clientEmail"
                  errors={errors.clientEmail}
                  className="form-control"
                  placeholder="Enter Email"
                  pattern={{ type: EMAIL, message: PATTERN_ERR_MSG }}
                />
              </div>
            </div>
          </div>
          <div className="d-flex px-2 mt-3">
            <div className="col-4 px-2">
              <label>Address 1</label>
              <div className="mt-2">
                <InputFiled
                  type="text"
                  register={register}
                  name="clientAddress1"
                  errors={errors.clientAddress1}
                  className="form-control"
                  placeholder={placeholder}
                  pattern={{
                    type: ALPHANUM_SPACE_REGEX,
                    message: PATTERN_ERR_MSG,
                  }}
                />
              </div>
            </div>
            <div className="col-4 px-2">
              <label>Address 2</label>
              <div className="mt-2">
                <InputFiled
                  type="text"
                  register={register}
                  name="clientAddress2"
                  errors={errors.clientAddress2}
                  className="form-control"
                  placeholder={placeholder}
                  pattern={{
                    type: ALPHANUM_SPACE_REGEX,
                    message: PATTERN_ERR_MSG,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="d-flex px-2 mt-3">
            <div className="col-4 px-2">
              <label>City</label>
              <div className="mt-2">
                <InputFiled
                  type="text"
                  register={register}
                  name="clientCity"
                  errors={errors.clientCity}
                  className="form-control"
                  placeholder={placeholder}
                  pattern={{
                    type: ALPHANUM_SPACE_REGEX,
                    message: PATTERN_ERR_MSG,
                  }}
                />
              </div>
            </div>
            <div className="col-4 px-2">
              <label>State/Province</label>
              <div className="mt-2">
                <SelectFormTag
                  name="clientState"
                  register={register}
                  className="form-select"
                  errors={errors?.clientState}
                  placeholder="Select"
                >
                  {stateList.map((cc, index) => (
                    <option key={index} value={cc.value}>
                      {cc.value}
                    </option>
                  ))}
                </SelectFormTag>
              </div>
            </div>
          </div>
          <div className="d-flex px-2 mt-3">
            <div className="col-4 px-2">
              <label>Zip/Postal COde</label>
              <div className="mt-2">
                <SelectFormTag
                  name="clientZip"
                  register={register}
                  className="form-select"
                  errors={errors?.clientZip}
                  placeholder="Select"
                >
                  {zipList.map((cc, index) => (
                    <option key={index} value={cc.value}>
                      {cc.value}
                    </option>
                  ))}
                </SelectFormTag>
              </div>
            </div>
            <div className="col-4 px-2">
              <label>Country</label>
              <div className="mt-2">
                <SelectFormTag
                  name="clientCountry"
                  register={register}
                  className="form-select"
                  errors={errors?.clientCountry}
                  placeholder="Enter"
                >
                  {countryList.map((cc, index) => (
                    <option key={index} value={cc.value}>
                      {cc.value}
                    </option>
                  ))}
                </SelectFormTag>
              </div>
            </div>
          </div>
          <div className="d-flex px-2 mt-3">
            <div className="col-4 px-2">
              <label>Logo Upload</label>
              <div className="mt-2 d-flex position-relative">
                <InputFiled
                  type="file"
                  register={register}
                  name="clientImg"
                  errors={errors.clientImg}
                  className="position-absolute upload-file"
                  placeholder="Enter Code"
                />
                <button className="sph-btn sph-shift rounded-pill">
                  Choose Image
                </button>
                <span className="align-self-center ms-2">
                  There is no image added
                </span>
              </div>
            </div>
            <div className="col-4 d-flex px-2 pb-1 justify-content-between align-self-end">
              <div>
                <label>Use this logo on pack slips</label>
              </div>
              <div className="form-check form-switch sidebar-toggler">
                <div>
                  {/* <input className="form-check-input cursor-pointer" type="checkbox" role="switch" id="" checked /> Yes */}
                  <InputFiled
                    type="checkbox"
                    register={register}
                    name="clientImgLogoStatus"
                    errors={errors.clientImgLogoStatus}
                    className="form-check-input cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="my-2 bg-white rounded py-3">
        <div className="d-flex flex-row-reverse">
          <FormButton
            className="sph-btn me-4"
            handleClick={handleSubmit(nextData)}
          >
            Next
          </FormButton>
          <FormButton
            className="sph-btn sph-shift me-4"
            handleClick={handelCancelBtn}
          >
            Cancel
          </FormButton>
        </div>
      </div>
    </>
  );
}
