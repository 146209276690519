import RouteNavigator from "../RouteNavigator";
import "../assets/css/style.css";

export default function Pages() {
  return (
    <div className="col-md-9 ms-sm-auto col-lg-10 px-md-3">
      <RouteNavigator />
    </div>
  );
}
