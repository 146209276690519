import React, { useEffect, useState } from "react";
import { TabProps } from "./TabProps";

const Tabs: React.FC<TabProps<any>> = ({ tabs, listLength }) => {
  const [activeTab, setActiveTab] = useState(0);

  const setLength = () => {
    if (listLength != null) {
      tabs.map((item, index) => {
        index > listLength
          ? (item.visibility = false)
          : (item.visibility = true);
      });
    }
  };

  const [items, setItems] = useState(tabs);

  const handleDropdownItemsClick = (clickedItem: any) => {
    const updatedItems = items.map((item) => {
      if (item.visibility) {
        // If the item is currently in the list
        if (item?.order === activeTab) {
          // Swap visibility status and set status of clickedItem to true
          return { ...item, visibility: false };
        }
      }
      // For other items, return as it is
      return item;
    });

    setActiveTab(clickedItem?.order);

    // Set the status of the clicked item to true
    const updatedClickedItem = { ...clickedItem, visibility: true };

    // Update the items array with the updated items and clickedItem
    setItems([
      updatedClickedItem,
      ...updatedItems.filter((item) => item?.order !== clickedItem.order),
    ]);
    generateListItems();
  };

  const generateListItems = () => {
    setLength();
    return items
      .filter((item) => item.visibility)
      .map((item, index) => (
        <li className="tab-item" key={item.order}>
          <span
            className={`nav-link${
              item.order === activeTab ? " active-tab" : ""
            }`}
            onClick={() => {
              setActiveTab(item.order || 0);
            }}
          >
            {item.label}
          </span>
        </li>
      ));
  };
  let listItems = generateListItems();

  const generateDropdownItems = () => {
    return items
      .filter((item) => !item.visibility)
      .map((item) => (
        <li
          className="nav-item"
          key={item.order}
          onClick={() => handleDropdownItemsClick(item)}
        >
          {item.label}
        </li>
      ));
  };
  let dropdownItems = generateDropdownItems();

  return (
    <div>
      {/* Render the tab labels */}

      <div>
        <div className="px-4 bg-white rounded-top">
          <ul className="nav">
            {dropdownItems.length === 0 ? (
              listItems
            ) : (
              <>
                {listItems}
                <li className="nav-item">
                  <div className="dropdown">
                    <a
                      className="btn btn-secondary dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    ></a>

                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      {dropdownItems.map((item, index) => item)}
                    </ul>
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>

      {/* Render the content of the active tab */}
      <div>{tabs[activeTab].content}</div>
    </div>
  );
};

export default Tabs;
