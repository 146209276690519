import React, { useState } from "react";
import PageHeader from "../../Layout/PageHeader";
import ClientBasicInfo from "./ClientBasicInfo";
import { BasicInfo, ClientData, Mapping } from "./Client.type";
import TabProgressBar from "../../Shared/Tab/TabProgressBar";
import ClientMapping from "./ClientMapping";
export default function ClientAddEdit() {
  const pageName = "Create Client";
  const [clientData, setClientData] = useState<ClientData>({
    basicInfo: new BasicInfo(),
    mapping: new Mapping(),
  });
  const handelAllTabData = (data: ClientData) => {
    setClientData(data);
  };

  const TabProgressBarData = [
    {
      step: 1,
      label: "Basic Information",
      content: (
        <ClientBasicInfo
          handelAllTabData={handelAllTabData}
          clientData={clientData}
        />
      ),
    },
    {
      step: 2,
      label: "Mapping",
      content: <ClientMapping/>,
    },
    {
      step: 3,
      label: "Accessorial Charges",
      content: "",
    },
    {
      step: 4,
      label: "Package Type",
      content: "",
    },
    {
      step: 5,
      label: "Productivity",
      content: "",
    },
    {
      step: 6,
      label: "Setting",
      content: "",
    },
  ];
  return (
    <>
      <PageHeader
        addUrl="/clientCreate"
        listUrl="/clientList"
        gridUrl="/clientGrid"
        name={pageName}
        viewType={true}
      />
      <div className="clearfix"></div>

      <h5 className="text-start mb-3">{pageName}</h5>
      <TabProgressBar tabs={TabProgressBarData} />
    </>
  );
}
