export const NUMBER_REGEX = /^[0-9]*$/;
export const NAME_REGEX = /^[\w\-_(),.\s]*$/;
export const PHONE_NUM = /^[0-9]{8,14}$/;
export const PHONE_NUM_CODE =  /^\+[0-9]{8,14}$/;
export const POST_CODE = /^[0-9]{8}$/;
export const ALPHANUM_SPACE_REGEX = /^([ ]{0,1}[a-zA-Z0-9]+[ ]{0})*$/;
export const ALPHANUM_REGEX = /^[a-zA-Z0-9]+$/;
export const ALPHA_ONE_SPACE_REGEX = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
export const EMAIL = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;


export const countryList = [
    { key: "1", value: "USA" },
    { key: "2", value: "UK" },
    { key: "3", value: "UAE" },
    { key: "4", value: "SA" },
  ];

  export const stateList = [
    { key: "1", value: "Alaska" },
    { key: "2", value: "California" },
    { key: "3", value: "Florida" },
    { key: "4", value: "New Jersey" },
    { key: "5", value: "New York" },
    { key: "6", value: "New Jersey" },
    { key: "7", value: "New Jersey" },
    { key: "8", value: "New Jersey" },
  ];

  export const zipList = [
    { key: "1", value: "585785" },
    { key: "2", value: "789456" },
    { key: "3", value: "894562" },
  ];


  export const clientWarehouseList = [
    { label: "Florida", value: "Florida" },
    { label: "Chicago", value: "Chicago" },
    { label: "CargoCraft", value: "CargoCraft" },
    { label: "OptiStore", value: "OptiStore" },
    { label: "EliteGoods", value: "EliteGoods" },
    { label: "FlexiSpace", value: "FlexiSpace" },
    { label: "Store Hub", value: "Store Hub" },
    { label: "SmartStore", value: "SmartStore" },
  ];
  
  export const inboundOutboundList = [
    {
      category: "Inbound",
      key: "Inbound return order",
    },
    {
      category: "Inbound",
      key: "Return order with one Step",
    },
    {
      category: "Inbound",
      key: "Inbound one Step",
    },
    {
      category: "Outbound",
      key: "Single order with audit",
    },
    {
      category: "Outbound",
      key: "Multi shipment without audit",
    },
    {
      category: "Outbound",
      key: "Single order with Package assignment",
    },
  ];