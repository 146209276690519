import { Route, Routes } from "react-router-dom";
import CustomerAddEdit from "./Pages/Customer/CustomerAddEdit";
import CustomerGridView from "./Pages/Customer/CustomerGridView";
import CustomerOverview from "./Pages/Customer/CustomerOverview";
import ClientOverview from "./Pages/Client/ClientOverview";
import ClientGridView from "./Pages/Client/ClientGridView";
import ClientDetails from "./Pages/Client/ClientDetails";
import ClientAddEdit from "./Pages/Client/ClientAddEdit";

const RouteNavigator = () => {
  return (
    <div>
      <Routes>
        <Route path="/custGrid" element={<CustomerGridView />} />
        <Route path="/custList" element={<CustomerOverview />} />
        <Route path="/addCustomer" element={<CustomerAddEdit />} />
        <Route path="/clientList" element={<ClientOverview />} />
        <Route path="/clientGrid" element={<ClientGridView/>}/>
        <Route path="/clientDetails/:id" element={<ClientDetails/>}/>
        <Route path="/clientCreate" element={<ClientAddEdit/>}/>

      </Routes>
    </div>
  );
};

export default RouteNavigator;
