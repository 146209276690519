import React from 'react'
import { ShipToCodeData } from './ShipToCode.type'
import Table, { Column } from "../../../Components/Table";
import { PlusCircle, ThreeDotsVertical } from 'react-bootstrap-icons';

interface viewProps{
    shipToCodeList:ShipToCodeData[];
}
const columns: Column[] = [
    {
      name: "Ship to Code",
      selector: (row: { shipCode: string }) => row.shipCode,
    },
    {
      name: "First Name",
      selector: (row: { firstName: string }) => row.firstName,
    },
    {
      name: "Last Name",
      selector: (row: { lastName: string }) => row.lastName,
    },
    {
      name: "Address 1",
      selector: (row: { address1: string }) => row.address1,
    },
    {
      name: "Address 2",
      selector: (row: { address2: string }) => row.address2,
    },
    {
      name: "City",
      selector: (row: { city: string }) => row.city,
    },
    {
      name: "State",
      selector: (row: { state: string }) => row.state,
    },
    {
        name: "Zip",
        selector: (row: { postalCode: string }) => row.postalCode,
      },
    {
      name: "Country",
      selector: (row: { country: string }) => row.country,
    },
   
    {
      name: '',
      selector: (row: any) => <ThreeDotsVertical />,
    },
  ];
export default function ShipToCodeView(props:viewProps) {
    const {shipToCodeList}=props;
  return (
    <div>
      <div className="customerTableMainDiv">
      <Table columns={columns} data={shipToCodeList} />
    </div>
    </div>
  )
}
